import React, { useState, useEffect } from 'react'
import { Content } from 'partials/TopPage/Content'
import * as Product from 'modules/product/components/Provider'
import * as Campaign from 'modules/campaign/Provider'
import { FILTER_TYPE } from 'modules/product/components/Filter'
import { graphql } from 'gatsby'

/**
 *
 */
const PICK_UP_DATA_COUNT = 6

/**
 *
 */
const pickRandom = (src, array, picUpCount) => {
  if (src.length === array.length) {
    return array
  }

  if (array.length === picUpCount) {
    return array
  }

  const index = Math.floor(Math.random() * src.length)
  const unique = array.filter((element) => element === src[index])
  if (unique.length !== 0) {
    return pickRandom(src, array)
  }

  const newArray = [...array, src[index]]
  return pickRandom(src, newArray)
}

/**
 * TopPage
 */
const Top = ({ data, pageContext }) => {
  const recentPosts = data.recentPosts.nodes
  const [recommendPosts, setPosts] = useState(null)
  useEffect(() => {
    setPosts(pickRandom(data.recommendPosts.nodes, [], PICK_UP_DATA_COUNT))
  }, [])
  // if (!recommendPosts) {
  //   return <div>Initializing</div>
  // }
  return (
    <Campaign.Provider value={{ data, pageContext }}>
      <Product.Provider
        value={{
          [FILTER_TYPE.RECOMMEND_POSTS]: recommendPosts,
          [FILTER_TYPE.RECENT_POSTS]: recentPosts,
        }}
      >
        <Content />
      </Product.Provider>
    </Campaign.Provider>
  )
}

export const query = graphql`
  query TopPageQuery($postStatus: String!) {
    recommendPosts: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "products", nin: "archive" } } }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        id
        title
        content
        wordpress_id
        date
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300)
            }
          }
        }
      }
    }
    recentPosts: allWordpressPost(
      limit: 10
      sort: { order: DESC, fields: date }
      filter: {
        categories: { elemMatch: { slug: { eq: "products", nin: "archive" } } }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        id
        title
        content
        wordpress_id
        date
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300)
            }
          }
        }
      }
    }
    campaignPosts: allWordpressPost(
      limit: 6
      sort: { order: DESC, fields: date }
      filter: {
        categories: { elemMatch: { slug: { eq: "campaign", nin: "archive" } } }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        categories {
          slug
        }
        id
        title
        content
        wordpress_id
        date
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300)
            }
          }
        }
      }
    }
    campaignBanner: allWordpressPost(
      limit: 6
      sort: { order: DESC, fields: date }
      filter: {
        categories: { elemMatch: { slug: { eq: "campaign", nin: "archive" } } }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        categories {
          slug
        }
        id
        title
        content
        wordpress_id
        date
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
/**
 */
export default Top
