import React from 'react'
import { Default as BannerContent } from './Banner'
import { Content as RecommendPostsContent } from './RecommendProductPosts'
import { Content as RecentPostsContent } from './RecentProductPosts'
import { Default as CampaignPosts } from './CampaignPosts'
import * as styles from './styles.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Banner = () => <BannerContent />

/**
 *
 */
const Posts = () => (
  <div className={styles.tab_container}>
    <input id={styles.tab1} type="radio" name="tab_item" checked />
    <label class={styles.tab_item} for={styles.tab1}>
      おすすめ商品
      <StaticImage
        src="../../../static/img/_responsive_1.png"
        alt="_responsive_1"
        objectFit="scale-down"
        layout="constrained"
        placeholder="none"
        className={styles.icon_Content}
        quality="100"
      />
    </label>
    <input id={styles.tab2} type="radio" name="tab_item" />
    <label class={styles.tab_item} for={styles.tab2}>
      新着商品
      <StaticImage
        src="../../../static/img/_responsive_2.png"
        alt="_responsive_1"
        objectFit="scale-down"
        layout="constrained"
        placeholder="none"
        className={styles.icon_Content}
        quality="100"
      />
    </label>
    <input id={styles.tab3} type="radio" name="tab_item" checked />
    {process.env.FEATURE_CAMPAIGN === 'true' && (
      <>
        <label class={styles.tab_item} for={styles.tab3}>
          特集記事
          <StaticImage
            src="../../../static/img/04_icon.png"
            alt="_responsive_3"
            objectFit="scale-down"
            layout="constrained"
            placeholder="none"
            className={styles.icon_Content}
            quality="100"
          />
        </label>
        <div class={styles.tab_content} id={styles.tab3_content}>
          <div class={styles.tab_content_description}>
            <CampaignPosts />
          </div>
        </div>
      </>
    )}

    <div class={styles.tab_content} id={styles.tab1_content}>
      <div class={styles.tab_content_description}>
        <RecommendPosts />
      </div>
    </div>
    <div class={styles.tab_content} id={styles.tab2_content}>
      <div class={styles.tab_content_description}>
        <RecentPosts />
      </div>
    </div>
  </div>
)

/**
 *
 */
const RecommendPosts = () => <RecommendPostsContent />

/**
 *
 */
const RecentPosts = () => <RecentPostsContent />

/**
 *
 */
export const Content = () => (
  <Container>
    <Banner />
    <Posts />
  </Container>
)
